




















import {CartMixin} from '@/utils/mixins/CartMixin';
import FilterOptions from '@/components/filter/FilterOptions.vue';
import SampleDetails from '@/components/sample-selector/SampleDetails.vue';
import SampleList from '@/components/sample-selector/SampleList.vue';
import {getGlobalTimeline} from '@/store/shared';
import {gsap, Power2} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import Cookies from 'js-cookie';
import TrButton from '@/components/shared/TrButton.vue';
import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import {mixins} from "vue-class-component";

gsap.registerPlugin(ScrollToPlugin);

@Component({
  name: 'SampleSelector',
  components: {
    FilterOptions,
    SampleDetails,
    SampleList,
    TrButton,
    IconShopping,
  },
})
export default class SampleSelector extends mixins(CartMixin) {
  @Prop({ default: false }) private enableCollectionFilter!: boolean;
  @Ref('resultsSection') private resultsSection!: HTMLElement;

  get selectedItem() {
    return this.$store.getters['productSearch/selectedItem'];
  }

  get lastUpdate() {
    return this.$store.getters['productSearch/lastResultsUpdate'];
  }

  private mounted() {
    const sampleSearchMode = Cookies.get('sampleSearchMode');

    if(sampleSearchMode) {
      this.$store.dispatch('productSearch/setSampleSearchMode', sampleSearchMode);
      this.$store.dispatch('productSearch/setActiveFilters', sampleSearchMode);
    }

    this.$store.dispatch('productSearch/performSearch');
  }

  @Watch('$store.state.productSearch.selectedItem')
  private onSelectedItemChange() {
    if (this.selectedItem) {
      this.scrollToTopOfResults();
    }
  }

  private scrollToTopOfResults() {
    this.$nextTick(() => {
      getGlobalTimeline(this).add(
          gsap.to(window, {
            scrollTo: {
              y: this.resultsSection,
              offsetY: 150,
              // autoKill: true
            },
            duration: 0.5,
            ease: Power2.easeOut,
          })
      );
    });
  }
}
