











import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { ImageHelpers } from '@/utils/imageHelpers';
import { CollectionType } from '@/typings/enums/CollectionType';

@Component({ name: 'SidePanelCollection' })
export default class SidePanelCollection extends Mixins(ImageHelpers) {
  @Prop() collections: CollectionType[] | undefined;

  beforeMount() {
    const order = [CollectionType.FOCUS, CollectionType.LUMEN, CollectionType.METALLICS, CollectionType.NATURALS, CollectionType.UNI_COLOURS, CollectionType.WOOD_DECORS];

    this.collections!.sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
  }
}
