























































import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import Badge from '@/components/navigation/Badge.vue';
import TrButton from '@/components/shared/TrButton.vue';
import {BreakPoint, mqMinWidth} from '@/utils/mq';
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import SampleBasket from "@/components/sample-selector/SampleBasket.vue";
import TrModal from "@/components/shared/TrModal.vue";
import SampleBasketOrderModal from "@/components/sample-selector/SampleBasketOrderModal.vue";
import {Category} from "@/typings/interfaces/Misc";

@Component({
  components: {SampleBasketOrderModal, TrButton, IconShopping, Badge, SampleBasket, TrModal},
})
export default class OrderSampleButton extends Vue {
  @Prop({required: true, default: '#'}) link!: string;

  @Prop({required: true}) readonly salutations!: Array<Category>;
  @Prop({required: true}) readonly professions!: Array<Category>;
  @Prop({required: true}) readonly countries!: Array<Category>;
  @Prop({required: true}) readonly regions!: Array<Category>;
  @Prop({required: true}) readonly privacyPolicyLink!: string;
  @Prop({required: true}) readonly formStepOneLabel!: string;
  @Prop({required: true}) readonly formStepTwoLabel!: string;
  @Prop({required: true}) readonly salutationLabel!: string;
  @Prop({required: true}) readonly firstNameLabel!: string;
  @Prop({required: false}) readonly honeyNameLabel!: string;
  @Prop({required: false}) readonly reverseFieldLabel!: string;
  @Prop({required: true}) readonly lastNameLabel!: string;
  @Prop({required: true}) readonly companyNameLabel!: string;
  @Prop({required: true}) readonly houseNumberLabel!: string;
  @Prop({required: true}) readonly addressLabel!: string;
  @Prop({required: true}) readonly countryLabel!: string;
  @Prop({required: true}) readonly cityLabel!: string;
  @Prop({required: true}) readonly zipcodeLabel!: string;
  @Prop({required: true}) readonly emailLabel!: string;
  @Prop({required: true}) readonly phoneLabel!: string;
  @Prop({required: true}) readonly professionLabel!: string;
  @Prop({required: true}) readonly startDateLabel!: string;
  @Prop({required: true}) readonly squareFeetLabel!: string;
  @Prop({required: true}) readonly otherLabel!: string;
  @Prop({required: true}) readonly newsletterLabel!: string;
  @Prop({required: true}) readonly privacyPolicyLabel!: string;
  @Prop({required: true}) readonly regionLabel!: string;
  @Prop({required: true}) readonly sampleSelectorLink!: string;
  @Prop({required: true}) readonly blockList!: string;

  @Prop() readonly introText!: string;
  @Prop() readonly moveToStepTwoText!: string;
  @Prop({required: true}) readonly orderPlacedMessage!: string;

  // private showFullButton: boolean = false;

  private hideCloseCross: boolean = false;
  private modalWidth: string | null = null;
  @Ref('sampleBasketOrderModal') private sampleBasketOrderModal!: SampleBasketOrderModal;

  private handleApplySampleOrderFinishedStylingEvent(doApply: boolean) {
    if (doApply) {
      this.hideCloseCross = true;
      this.modalWidth = '700px'
    } else {
      this.hideCloseCross = false;
      this.modalWidth = null;
    }
  }
  private closeSampleBasketOrderModal() {
    this.sampleBasketOrderModal.close();
  }

  get basketItemCount() {
    // return 3;
    return this.$store.getters['shoppingCart/count'];
  }

  get isSampleBasketOrderModalOpen(): boolean {
    return this.$store.getters['ordering/isSampleBasketOrderModalOpen'];
  }

  get isShowBasket() {
    return this.$store.getters['shoppingCart/isModalOpen'];
  }

  closeBasket() {
    this.$store.dispatch('shoppingCart/openBasket', false);
  }

  mounted() {
    const mq = mqMinWidth(BreakPoint.XL);
    // this.showFullButton = mq.matches;
    mq.addEventListener('change', (e) => {
      // this.showFullButton = e.matches;
      this.$store.dispatch('shoppingCart/openBasket', false);
    });
  }

  public openSampleBasketModal() {
    this.$store.dispatch('ordering/setSampleBasketOrderModalOpen', true);
  }

  public openSampleSelector() {
    window.location.href = this.link + "#shopping-bag";
  }


}
