import { render, staticRenderFns } from "./SampleDetails.vue?vue&type=template&id=20900a82&scoped=true&"
import script from "./SampleDetails.vue?vue&type=script&lang=ts&"
export * from "./SampleDetails.vue?vue&type=script&lang=ts&"
import style0 from "./SampleDetails.vue?vue&type=style&index=0&id=20900a82&lang=scss&scoped=true&"
import style1 from "./SampleDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20900a82",
  null
  
)

export default component.exports