import AccordionWrap from '@/components/global/AccordionWrap.vue';
import BreadcrumbsCustom from '@/components/global/BreadcrumbsCustom.vue';
import DealerLocator from '@/components/global/DealerLocator.vue';
import DealerLocatorSearch from '@/components/global/DealerLocatorSearch.vue';
import DocumentationTabs from '@/components/global/DocumentationTabs.vue';
import GoogleMaps from '@/components/global/GoogleMaps.vue';
import GridVisual from '@/components/global/GridVisual.vue';
import InspirationOverview from '@/components/global/InspirationOverview.vue';
import LanguageSelector from '@/components/global/LanguageSelector.vue';
import MaterialsComponent from '@/components/global/MaterialsComponent.vue';
import PortalWrapper from '@/components/global/PortalWrapper.vue';
import SalesSearch from '@/components/global/SalesSearch.vue';
import SearchResults from '@/components/global/SearchResults.vue';
import TrHambButton from '@/components/global/TrHambButton.vue';
import VideoComponent from '@/components/global/VideoComponent.vue';
import StickyRequestButton from '@/components/sample-selector/StickyRequestButton.vue';
import Projects from '@/components/global/Projects.vue';
import Footer from '@/components/layout/Footer.vue';
import LinkInterceptionComponent from '@/components/LinkInterceptionComponent.vue';
import Navbar from '@/components/navigation/Navbar.vue';
import NavigationSearch from '@/components/navigation/NavigationSearch.vue';
import OrderSampleButton from '@/components/navigation/OrderSampleButton.vue';
import NewsletterComponent from '@/components/NewsletterComponent.vue';
import Hero from '@/components/page-home/Hero.vue';
import Inspiration from '@/components/page-home/Inspiration.vue';
import TextImageVideo from '@/components/page-product-details/TextImageVideo.vue';
import DecorSet from '@/components/page-product-details/DecorSet.vue';
import ProductOverviewDesc from '@/components/product-overview/ProductOverviewDesc.vue';
import ProductOverviewPanel from '@/components/product-overview/ProductOverviewPanel.vue';
import ProductOverviewTab from '@/components/product-overview/ProductOverviewTab.vue';
import TrButton from '@/components/shared/TrButton.vue';
import TrDropdown from '@/components/shared/TrDropdown.vue';
import TrModal from '@/components/shared/TrModal.vue';
import TrSidePanel from "@/components/side-panel/TrSidePanel.vue";
import TopTask from '@/components/top-task/TopTask.vue';
import ImageCTAHeader from '@/components/page-home/ImageCTAHeader.vue';
import enterFade from '@/directives/enterFade';
import enterUpFade from '@/directives/enterUpFade';
import lineDrip from '@/directives/lineDrip';
import pswpTrigger from '@/directives/pswp-trigger';
import i18n from '@/i18n-setup';
import AddToCartLink from '@/components/sample-selector/AddToCartLink.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'

// Components
import SampleSelector from '@/views/SampleSelector.vue';
import UiKit from '@/views/UiKit.vue';

// TODO: include treeshaking for this
import {Checkbox, DatePicker, Form, FormItem, Input, Option, Radio, RadioGroup, Select, Slider} from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import PortalVue from 'portal-vue';

// vue-awesome-swiper
import 'swiper/css/swiper.min.css';

import Vue from 'vue';
// TODO: check this with jeffrey
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import store from './store';
import DocumentationLink from "@/components/DocumentationLink.vue";

// Set defaultProfile (other)
import PersonalizationHelper from '@/utils/PersonalizationHelper';
PersonalizationHelper.setDefaultConfig();

// @ts-ignore
require('./scss/app.scss');

/* eslint-disable */
document.addEventListener('touchstart', () => { /* EMPTY ON PURPOSE */
}, false);
declare let __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + '/' + __webpack_public_path__;
/* eslint-enable */
Vue.use(VueBreadcrumbs);

const useFromElementUi = [
    Input, Checkbox, Radio, RadioGroup, Form, FormItem, Select, Option, DatePicker, Slider,
];

locale.use(lang);

useFromElementUi.forEach((elementUiComponent) => {
    Vue.component(elementUiComponent.name, elementUiComponent);
});

Vue.config.productionTip = false;
Vue.use(PortalVue);

Vue.use(VueReCaptcha, {siteKey: _mgnl.recaptchaCode})

if (typeof ResizeObserver === 'function') {
    const resizeObserver = new ResizeObserver(() => {
        // Update scroll trigger when the dimensions of the page change
        ScrollTrigger.refresh(true);
    });
    resizeObserver.observe(document.body);
}

const app = new Vue({
    store,
    i18n,
    comments: true,
    el: '#app',
    components: {
        'accordion-wrap': AccordionWrap,
        'breadcrumbs': BreadcrumbsCustom,
        'decor-set': DecorSet,
        'documentation-tabs': DocumentationTabs,
        'hero': Hero,
        'inspiration': Inspiration,
        'materials-component': MaterialsComponent,
        'product-overview-desc': ProductOverviewDesc,
        'product-overview-panel': ProductOverviewPanel,
        'product-overview-tab': ProductOverviewTab,
        'sample-selector': SampleSelector,
        'search-results': SearchResults,
        'image-cta-header': ImageCTAHeader,
        'text-image-video': TextImageVideo,
        'top-task': TopTask,
        'tr-button': TrButton,
        'tr-dropdown': TrDropdown,
        'tr-footer': Footer,
        'tr-modal': TrModal,
        'tr-side-panel': TrSidePanel,
        'ui-kit': UiKit,
        'video-component': VideoComponent,
        'inspiration-overview': InspirationOverview,
        'google-maps': GoogleMaps,
        'dealer-locator': DealerLocator,
        'dealer-locator-search': DealerLocatorSearch,
        'sales-search': SalesSearch,
        'language-selector': LanguageSelector,
        'grid-visual': GridVisual,
        'portal-wrapper': PortalWrapper,
        'navbar': Navbar,
        'order-sample-button': OrderSampleButton,
        'tr-hamb-button': TrHambButton,
        'navigation-search': NavigationSearch,
        'link-interception-component': LinkInterceptionComponent,
        'newsletter-component': NewsletterComponent,
        'add-to-cart-link': AddToCartLink,
        'sticky-request-button': StickyRequestButton,
        'projects': Projects,
        'documentation-link' : DocumentationLink
    },
    directives: {
        enterFade,
        enterUpFade,
        lineDrip,
        pswpTrigger,
    },
});

export default app;
