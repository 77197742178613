






























import StickyRequestButton from "@/components/sample-selector/StickyRequestButton.vue";
import {MainCtaData, CtaData} from '@/typings/interfaces/Misc';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {parseTextFields} from "@/utils/StringUtils";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {isMobile} from '@/utils/helpers';
import _ from "lodash";

@Component({
  name: 'ImageCTAHeader',
  components: {
    StickyRequestButton,
    Swiper,
    SwiperSlide,
  },
})
export default class ImageCTAHeader extends Vue {
  @Prop({required: true}) readonly mainCta!: Array<MainCtaData>;
  @Prop({required: true}) readonly ctasData!: Array<CtaData>;

  optionsBg = {
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  };

  public activeTab: number|null = null;
  public bottomPadding: string = '0px';
  private bgSwiper = new Swiper;

  switchSlide(slide: number) {
    this.bgSwiper.slideTo(slide);
    this.activeTab = slide;
  }
  get parsedCTAsData() {
    return parseTextFields(this.ctasData);
  }

  get parsedCTAData() {
    return parseTextFields(this.mainCta) as MainCtaData;
  }

  private debouncedCalculatePadding = _.debounce(() => this.calculatePadding(), 200);

  private calculatePadding() {
    this.bottomPadding = window.matchMedia('(max-width: 1024px)').matches ? '0px' : `calc(${(this.$refs.ctasContainer as HTMLElement).clientHeight}px + 2rem)`;
  }

  mounted() {
    this.bgSwiper = (this.$refs.swiperBg as any).$swiper;

    this.bgSwiper.on('resize', () => {
      this.debouncedCalculatePadding()
    });

    if(isMobile) {
      let randomSlide = Math.floor(Math.random() * this.bgSwiper.slides.length);
      this.switchSlide(randomSlide);
    }
    else {
      this.switchSlide(0);
      this.debouncedCalculatePadding();
    }
  }
}
