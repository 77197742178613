import {ColourTint} from '@/typings/enums/ColourTint';
import {FinishImages, FinishType} from '@/typings/enums/FinishType';
import {ProductLineImages, ProductLineType} from '@/typings/enums/ProductLineType';
import enumKey from '@/utils/enumKey';
import requireAsset from '@/utils/requireAsset';
import Vue from 'vue';
import Component from 'vue-class-component';
import {CollectionType, CollectionImages} from "@/typings/enums/CollectionType";

@Component
export class ImageHelpers extends Vue {

    public collectionsImage(name: CollectionType): string | undefined {
        const collectionKey: keyof typeof CollectionType | undefined = enumKey(CollectionType, name);
        if (collectionKey != undefined) {
            const collectionImage: CollectionImages = CollectionImages[collectionKey];
            return this.requireAsset(`collections/${collectionImage}`);
        }
        return;
    }

    public colourTintsImage(name: ColourTint): string {
        return this.requireAsset(`colour-tints/${name}.svg`);
    }

    public productLineEnumKey(name: ProductLineType): string | undefined {
        const productLineKey: keyof typeof ProductLineType | undefined = enumKey(ProductLineType, name);
        if (productLineKey != undefined) {
            return productLineKey;
        }
    }

    public productLineImage(name: ProductLineType): string | undefined {
        const productLineKey: keyof typeof ProductLineType | undefined = enumKey(ProductLineType, name);
        if (productLineKey != undefined) {
            const productLineImage: ProductLineImages = ProductLineImages[productLineKey];
            return this.requireAsset(`product-lines/${productLineImage}`);
        }
        return;
    }

    public finishesImage(name: FinishType): string | undefined {
        const finishKey: keyof typeof FinishType | undefined = enumKey(FinishType, name);
        if (finishKey != undefined) {
            const finishImage: FinishImages = FinishImages[finishKey];
            return this.requireAsset(`finishes/${finishImage}`);
        }
        return;
    }

    public requireAsset = requireAsset;


}
