/* Keep in sync with CollectionType.ftl */
export enum CollectionType {
    LUMEN = 'lumen',
    METALLICS = 'metallics',
    FOCUS = 'focus',
    NATURALS = 'naturals',
    WOOD_DECORS = 'wood-decors',
    UNI_COLOURS = 'uni-colours'
}

export enum CollectionImages {
    LUMEN = 'lumen.jpg',
    METALLICS = 'metallics.jpg',
    FOCUS = 'focus.jpg',
    NATURALS = 'naturals.jpg',
    WOOD_DECORS = 'wood-decors.jpg',
    UNI_COLOURS = 'uni-colours.jpg'
}
